<template>
  <div class="ma-10">
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <div class="form-ctn">
      <h1 style="margin-top: 10px; padding-top: 15px">Ubah Data User</h1>
      <p>Info Pembelian</p>
      <div class="col">
        <div class="form-row">
          <div class="content-form flex-item">
            <p>No Invoice</p>
            <v-text-field
              :disabled="isWatch"
              outlined
              class="form-input mt-2"
              placeholder="Masukkan No Invoice"
              v-model="form.invoice_number"
              clearable
            ></v-text-field>
          </div>
          <div class="content-form flex-item">
            <p>Waktu Pembelian</p>
            <v-text-field
              :disabled="isWatch"
              outlined
              class="form-input mt-2"
              placeholder="Masukkan Waktu Pembelian"
              v-model="form.purchase_time"
              clearable
            ></v-text-field>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-row">
          <div class="content-form">
            <p>Program</p>
            <v-text-field
              :disabled="isWatch"
              outlined
              class="form-input mt-2"
              placeholder="Masukkan Nama Lengkap"
              v-model="form.program"
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Tipe Kelas</p>
            <v-text-field
              :disabled="isWatch"
              outlined
              class="form-input mt-2"
              placeholder="Masukkan Nama Lengkap"
              v-model="form.class_type"
              clearable
            ></v-text-field>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-row">
          <div class="content-form">
            <p>Voucher</p>
            <v-text-field
              :disabled="isWatch"
              outlined
              class="form-input mt-2"
              placeholder="Masukkan Nama Lengkap"
              v-model="form.voucher"
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Nominal Transaksi</p>
            <v-text-field
              :disabled="isWatch"
              outlined
              class="form-input mt-2"
              placeholder="Masukkan Nama Lengkap"
              v-model="form.transaction_no"
              clearable
            ></v-text-field>
          </div>
        </div>
      </div>
      <div class="content-form" v-if="IswaitingForPayment">
        <p>Ubah Status</p>
        <v-select
          class="form-input"
          v-model="form.role"
          :items="status"
          :item-text="'label'"
          :item-value="'value'"
          outlined
          clearable
          placeholder="Ubah Status"
        ></v-select>
      </div>
      <p>Info Data Diri</p>
      <div class="content-form">
        <p>Title</p>
        <v-radio-group v-model="form.title" row>
          <v-radio
            v-for="title in titles"
            :key="title.value"
            :label="title.label"
            :value="title.value"
          ></v-radio>
        </v-radio-group>
      </div>

      <div class="content-form">
        <p>Nama Lengkap</p>
        <v-text-field
          :disabled="isWatch"
          outlined
          class="form-input mt-2"
          placeholder="Masukkan Nama Lengkap"
          v-model="form.full_name"
          clearable
        ></v-text-field>
      </div>

      <div class="content-form">
        <p>No. Handphone</p>
        <v-text-field
          prepend-icon="mdi-phone"
          :disabled="isWatch"
          outlined
          class="form-input mt-2"
          placeholder="Masukkan No. Handphone"
          v-model="form.phone_number"
          clearable
        ></v-text-field>
      </div>

      <div class="content-form">
        <p>Email</p>
        <v-text-field
          :disabled="isWatch"
          outlined
          class="form-input mt-2"
          placeholder="Masukkan Email"
          v-model="form.email"
          clearable
        ></v-text-field>
      </div>

      <div class="content-form">
        <p>Tanggal Lahir</p>
        <v-text-field
          outlined
          class="form-input mt-2"
          v-model="form.birthdate"
          type="date"
          clearable
        ></v-text-field>
      </div>
      <div v-if="isCompleted" class="switch">
        <v-card-title style="display: flex; align-items: center; padding: 0">
          <v-switch
            v-model="toggleValue"
            color="blue"
            dark
            @change="handleToggle"
          ></v-switch>
          <p>Geser untuk menonaktifkan transaksi pelanggan ini</p>
        </v-card-title>
      </div>
      <div v-if="openReason" class="content-form">
        <p>Alasan</p>
        <v-textarea
          counter="300"
          outlined
          class="form-input mt-2"
          v-model="form.alasan"
          clearable
        ></v-textarea>
      </div>

      <div class="submit-box" style="display: flex; justify-content: flex-end">
        <button
          class="btn-outline"
          @click="onCancel"
          style="margin-right: 10px"
        >
          BATALKAN
        </button>
        <button class="btn-solid" @click="onSubmit" :disabled="!validData">
          SIMPAN DATA
        </button>
      </div>
    </div>

    <Modal v-if="isModal" @close="() => (isModalDetail = false)" width="30%">
      <h4 class="mb-4" style="font-weight: 700; font-size: 24px">
        Konfirmasi Deactive Data
      </h4>
      <p
        style="
          font-size: 16px;
          font-weight: 500;
          color: #535353;
          text-align: center;
        "
      >
        Apakah kamu yakin ingin menonaktifkan data transaksi pelanggan ini?
      </p>
      <div class="btn-confirm">
        <button class="btn-outline" @click="cancelToggle">Batal</button>
        <button class="btn-solid" @click="confirmToggle">Ya</button>
      </div>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import Modal from "@/components/Modal.vue";

export default {
  name: "FormUserNonActive",
  components: { Modal },
  data() {
    return {
      IswaitingForPayment: true,
      breadcrumbs: [
        {
          text: "Finance",
          disabled: false,
          href: "/home",
        },
        {
          text: "(B2C) Bootcamp",
          disabled: false,
          href: "/transaction/b2b/bootcamp/",
        },
        {
          text: "(Batch 1) UI/UX Design",
          disabled: false,
          href: "/transaction/b2b/bootcamp/id",
        },
        {
          text: "Ubah Data User",
          disabled: true,
          href: "",
        },
      ],
      toggleValue: true,
      isModal: false,
      isCompleted: true,
      openReason: false,
      name: "Edit Data User",
      role: 2,
      id: null,
      isLoading: false,
      form: {
        title: "",
        full_name: "",
        program: "",
        class_type: "",
        voucher: "",
        transaction_no: "",
        phone_number: "",
        email: "",
        birthdate: "",
        role: "",
        alasan: "",
        password: "",
        confirmPassword: "",
      },
      titles: [
        { label: "Tuan", value: "mr" },
        { label: "Nona", value: "ms" },
        { label: "Nyonya", value: "mrs" },
      ],
      status: [
        { label: "Menunggu Pembayaran", value: "COMPLETED" },
        { label: "Pembayaran Lunas", value: "EXPIRED" },
      ],
      isWatch: false,
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  computed: {
    validData() {
      return (
        this.form.title &&
        this.form.full_name &&
        this.form.phone_number &&
        this.form.email &&
        this.form.birthdate &&
        this.form.role &&
        this.form.password &&
        this.form.confirmPassword &&
        this.form.password === this.form.confirmPassword
      );
    },
  },
  methods: {
    handleToggle() {
      if (!this.toggleValue) {
        this.isModal = true;
      } else {
        this.openReason = false;
        this.form.alasan = "";
      }
    },
    confirmToggle() {
      this.isModal = false;
      this.toggleValue = false;
      this.openReason = true;
    },
    cancelToggle() {
      this.isModal = false;
      this.toggleValue = true;
    },
    toggleColor() {
      if (!this.toggleValue) {
        this.openReason = true;
      } else {
        this.openReason = false;
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    onSubmit() {
      this.isLoading = true;
      setTimeout(() => {
        alert("User created successfully!");
        this.isLoading = false;
        this.$router.push("/transaction/b2b/bootcamp");
      }, 2000);
    },
    onCancel() {
      this.$router.push("/transaction/b2b/bootcamp/`" + this.name);
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.form = {
      title: "mr",
      full_name: "John Doe",
      phone_number: "1234567890",
      email: "john.doe@example.com",
      birthdate: moment("1990-01-01").format("YYYY-MM-DD"),
      role: "USER",
      password: "",
      confirmPassword: "",
    };
  },
};
</script>

<style scoped>
.form-ctn {
  margin: 34px 15px;
  width: 100%;
  height: auto;
  padding: 40px;
  background-color: #fff;
  border-radius: 20px;
}
::v-deep .v-breadcrumbs__item {
  color: #162ca2 !important;
  font-size: 14px !important;
  font-weight: 600;
}

::v-deep .v-breadcrumbs__item--disabled {
  color: #7b7e8c !important;
}
.switch p {
  padding: 0;
  margin: 0;
}
.col {
  padding: 0;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.content-form {
  flex: 1;
  margin-bottom: 20px;
}

.form-input {
  width: 100%;
}

.satu {
  font-size: small;
  color: blue;
}

.container-box {
  padding: 45px 50px;
}

.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}

.submit-box {
  display: flex;
  justify-content: flex-end;
}

.submit-box v-btn {
  margin-right: 10px;
}

.submit-box v-btn:nth-child(1) {
  background-color: blue;
}

.submit-box v-btn:nth-child(2) {
  background-color: grey;
}

button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[color="blue"] {
  background-color: #007bff;
  color: white;
}

button[color="grey"] {
  background-color: #6c757d;
  color: white;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.content-form {
  margin-bottom: 10px;
}

.phone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.phone-logo {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.phone-logo p {
  margin-left: 8px;
  margin: 0;
}

.form-input {
  width: 100%;
}

.v-input--switch:not(.v-input--switch--flat):not(.v-input--switch--inset)
  .v-input--switch__thumb {
  color: red;
}

.theme--light.v-input--switch .v-input--switch__track {
  color: red;
}

.btn-outline {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #d3d6e1;
  background-color: #fff;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #7b7e8c;
  width: 156px;
  height: 44px;
  padding: 6px 30px 6px 30px;
}

.btn-solid {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2d46cf;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  width: 156px;
  height: 44px;
  padding: 6px 30px 6px 30px;
}

.btn-confirm {
  display: flex;
  justify-content: center;
}
</style>
